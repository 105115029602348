<template>
  <div class="affairs">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="box">
      <div class="framework_box">
        <Title title="架构图" />
        <div class="framework">
          <img src="@/assets/images/product/affairs/bg1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box">
      <div class="application_box">
        <Title title="政务场景应用" />
        <div class="application">
          <div class="item" style="left: -252px">
            医疗器械企业主体责任落实系统
          </div>
          <div class="item" style="left: -294px">农渔业产业链数字化监控</div>
          <div class="item" style="left: -310px">数字化改革门户建设</div>
          <div class="item" style="left: -294px">数字经信企业+产业服务平台</div>
          <div class="item" style="left: -254px">
            数字化金融知识教育与宣传平台
          </div>
          <div class="item" style="left: 100px">市场安全综合监管系统</div>
          <div class="item" style="left: 34px">城市运行管理服务平台</div>
          <div class="item" style="left: 14px">一码通平台配套服务</div>
          <div class="item" style="left: 32px">医疗器械网销监管</div>
          <div class="item" style="left: 100px">
            数字辨妆—网销化妆品监测沙盘
          </div>
          <div class="item" style="right: -252px">电力无人营业厅软件系统</div>
          <div class="item" style="right: -298px">事业单位法人浙里看</div>
          <div class="item" style="right: -312px">电力营销知识库</div>
          <div class="item" style="right: -304px">环境保护管家小助手</div>
          <div class="item" style="right: -262px">
            数字平台综合项目出租房模块
          </div>
          <div class="item" style="right: 110px">数字化经信产业数据管理</div>
          <div class="item" style="right: 26px">数字化市场监管</div>
          <div class="item" style="right: 10px">疫情大物防监管</div>
          <div class="item" style="right: 26px">自主资源数字化管理</div>
          <div class="item" style="right: 108px">
            药品流通“黑匣子”中间库建设
          </div>
          <div class="middle">智慧政务</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Affairs",
  data() {
    return {
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/11.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.affairs {
  .box {
    display: flex;
    justify-content: center;
  }
  .framework_box {
    margin-top: 85px;
    .framework {
      margin-top: 70px;
      width: 1520px;
      height: 850px;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px #eeeeeeb3;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 1420px;
        height: 752px;
      }
    }
  }
  .application_box {
    width: 100vw;
    padding: 165px 0 80px;
    height: 632px;
    background: linear-gradient(180deg, #ffffff 0%, #ddeaff 101%);
    .application {
      position: relative;
      margin: 50px auto 0;
      height: 548px;
      width: 1045px;
      background-image: url(~@/assets/images/product/affairs/bg2.png);
      background-repeat: no-repeat;
      background-size: cover;
      .item {
        position: absolute;
        width: 300px;
        font-family: "AlibabaPuHuiTi-Regular";
        font-weight: 400;
        font-size: 20px;
        line-height: 40px;
        color: #333;
      }
      .item:nth-child(-n + 10) {
        text-align: right;
      }
      .item:nth-child(n + 11) {
        text-align: left;
      }
      .item:nth-child(1),
      .item:nth-child(11) {
        top: 22px;
      }
      .item:nth-child(2),
      .item:nth-child(12) {
        top: 138px;
      }
      .item:nth-child(4),
      .item:nth-child(14) {
        bottom: 140px;
      }
      .item:nth-child(3),
      .item:nth-child(8),
      .item:nth-child(13),
      .item:nth-child(18) {
        top: 252px;
      }
      .item:nth-child(5),
      .item:nth-child(15) {
        bottom: 26px;
      }
      .item:nth-child(6),
      .item:nth-child(16) {
        top: 93px;
      }
      .item:nth-child(7),
      .item:nth-child(17) {
        top: 172px;
      }
      .item:nth-child(9),
      .item:nth-child(19) {
        bottom: 173px;
      }
      .item:nth-child(10),
      .item:nth-child(20) {
        bottom: 95px;
      }
      .middle {
        position: absolute;
        width: 70px;
        left: 50%;
        top: 50%;
        margin-left: 5px;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 25px;
        letter-spacing: 0.5px;
        line-height: 40px;
      }
    }
  }
}
</style>
